import { navigate } from 'gatsby'
import { Button, Stack, Typography } from '@mui/material'
import { CallToAction, ChevronLeft, Home, ListAlt } from '@mui/icons-material'
import React, { useEffect, useState } from 'react'
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js'
import { LoadingButton } from '@mui/lab'

import { routePaths } from '../../constants/routes'
import {
  NextButtonContainer,
  StyledCardElement,
  RightContainer,
  LeftContainer,
  BillingContainer,
  ProductsList,
  ProductPreview,
  ProductDetail,
  ProductsContainer,
  ShippingContainer,
  TotalContainer,
  BillingTitleContainer,
  CardElementContainer,
  AcceptedPaymentContainer,
  SuccessPaymentContainer
} from './Styled'
import { useProfileContext } from '../../contexts/profileContext'
import { useAppContext } from '../../contexts/appContext'
import { buildImageLink, formatPrice } from '../../helpers'
import { createPaymentIntent, confirmPaymentIntent } from '../../helpers/api'

const acceptedPayment = [
  {
    name: 'CB',
    logo: `/CB.jpeg`
  },
  {
    name: 'Visa',
    logo: `/Visa.png`
  },
  {
    name: 'MasterCard',
    logo: `/MasterCard.png`
  },
  {
    name: 'AmericanExpress',
    logo: `/AmericanExpress.png`
  }
]

const Billing = () => {
  const [succeeded, setSucceeded] = useState(false)
  const [error, setError] = useState(null)
  const [submitting, setSubmitting] = useState(false)
  const [disabled, setDisabled] = useState(true)
  const stripe = useStripe()
  const elements = useElements()
  const profile = useProfileContext()
  const showSnackbar = useAppContext()?.showSnackbar
  const user = profile?.user
  const order = profile?.order
  const resetOrder = profile?.resetOrder

  useEffect(() => {
    if (order && !order.deliveryMode) navigate(routePaths.shoppingCart)
  }, [order])

  const handleChange = async event => {
    setDisabled(!event.complete)
    setError(event.error ? event.error.message : '')
  }

  const handleSubmit = async ev => {
    ev.preventDefault()
    setSubmitting(true)
    try {
      const data: { clientSecret: string } = await createPaymentIntent(order.id)
      const paymentResult = await stripe.confirmCardPayment(data.clientSecret, {
        setup_future_usage: 'on_session',
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name: `${order.billingAddress.firstName} ${order.billingAddress.lastName}`,
            email: user.email,
            phone:
              order.billingAddress.mobilePhone ||
              order.billingAddress.landlinePhone,
            address: {
              line1: order.billingAddress.address1,
              line2: order.billingAddress.address2,
              city: order.billingAddress.city,
              state: order.billingAddress.country,
              postal_code: order.billingAddress.postalCode
            }
          }
        },
        receipt_email: user.email,
        shipping: {
          name: `${order.shippingAddress.firstName} ${order.shippingAddress.lastName}`,
          phone:
            order.shippingAddress.mobilePhone ||
            order.shippingAddress.landlinePhone,
          address: {
            line1: order.shippingAddress.address1,
            line2: order.shippingAddress.address2,
            city: order.shippingAddress.city,
            state: order.shippingAddress.country,
            postal_code: order.shippingAddress.postalCode
          }
        }
      })
      if (paymentResult.error) {
        showSnackbar('error', paymentResult.error.message)
        setError(true)
      } else if (paymentResult.paymentIntent.status === 'succeeded') {
        await confirmPaymentIntent({ id: order.id })
        resetOrder()
        setSucceeded(true)
        showSnackbar('success', 'Paiement accepté !')
      }
    } catch (e) {
      setError(true)
      console.error(e)
      showSnackbar('error', "Le paiement n'a pas abouti, Veuillez reessayer")
      setSubmitting(false)
    } finally {
      setSubmitting(false)
    }
  }

  if (succeeded)
    return (
      <SuccessPaymentContainer>
        <Typography>Merci pour votre commande !</Typography>
        <Typography>
          Nous allons la traiter prochainement, vous serez informé de
          l&#39;avancement par email.
        </Typography>
        <Button
          variant="outlined"
          color="primary"
          startIcon={<ListAlt />}
          onClick={() => navigate(routePaths.orders)}
        >
          Voir mes commandes
        </Button>
        <Button
          variant="contained"
          color="primary"
          startIcon={<Home />}
          onClick={() => navigate(routePaths.home)}
        >
          Retour a l&#39;accueil
        </Button>
      </SuccessPaymentContainer>
    )

  if (!order) return null

  return (
    <>
      <BillingContainer>
        <LeftContainer>
          <Typography variant="h2">Résumé de votre commande</Typography>
          <ProductsList>
            {order.products
              .filter(product => order.productQuantities[product.id] > 0)
              .map(product => (
                <ProductsContainer>
                  <ProductPreview
                    src={buildImageLink(product.images[0].url)}
                    alt={product.images[0].alternativeText}
                  />
                  <ProductDetail>
                    <Typography component="span">
                      {`${product.title} x ${
                        order.productQuantities[product.id]
                      }`}
                    </Typography>
                    <Stack alignItems="flex-end">
                      {product.promotion ? (
                        <>
                          <Typography
                            sx={{ textDecoration: 'line-through' }}
                            component="span"
                          >
                            {formatPrice(
                              product.price *
                                order.productQuantities[product.id]
                            )}
                          </Typography>
                          <Typography component="span">
                            {formatPrice(
                              product.promotion *
                                order.productQuantities[product.id]
                            )}
                          </Typography>
                        </>
                      ) : (
                        <Typography component="span">
                          {formatPrice(
                            product.price * order.productQuantities[product.id]
                          )}
                        </Typography>
                      )}
                    </Stack>
                  </ProductDetail>
                </ProductsContainer>
              ))}
            <ShippingContainer>
              <Typography component="span">Livraison</Typography>
              <Typography component="span">
                {order.deliveryPrice === 0
                  ? 'Gratuit !'
                  : formatPrice(order.deliveryPrice)}
              </Typography>
            </ShippingContainer>
            <TotalContainer>
              <Typography component="span">Total</Typography>
              <Typography component="span">
                {formatPrice(order.totalPrice + order.deliveryPrice)}
              </Typography>
            </TotalContainer>
          </ProductsList>
        </LeftContainer>
        <RightContainer>
          <BillingTitleContainer>
            <Typography align="center" variant="h2">
              Paiement sécurisé par carte*
            </Typography>
            <AcceptedPaymentContainer>
              {acceptedPayment.map(({ name, logo }) => (
                <img key={name} src={logo} alt={name} width={25} />
              ))}
            </AcceptedPaymentContainer>
          </BillingTitleContainer>
          <CardElementContainer>
            <StyledCardElement
              options={{
                hidePostalCode: true,
                style: {
                  base: {
                    color: '#c78836',
                    '::placeholder': {
                      color: '#aab7c4'
                    }
                  }
                }
              }}
              onChange={handleChange}
            />
          </CardElementContainer>
        </RightContainer>
      </BillingContainer>
      <NextButtonContainer>
        <Button
          variant="outlined"
          color="secondary"
          disabled={submitting}
          onClick={() => navigate(`${routePaths.shoppingCart}?step=4`)}
          startIcon={<ChevronLeft />}
        >
          Précédent
        </Button>
        <LoadingButton
          disabled={disabled || succeeded || error}
          variant="contained"
          loading={submitting}
          color="primary"
          onClick={handleSubmit}
          style={{ minWidth: 150 }}
          startIcon={<CallToAction />}
        >
          Payer
        </LoadingButton>
      </NextButtonContainer>
      <Typography variant="caption" mt={4} component="p">
        * Paiement sécurisé via{' '}
        <Typography
          variant="caption"
          component="a"
          href="https://stripe.com/fr"
          target="_blank"
        >
          stripe
        </Typography>
      </Typography>
    </>
  )
}

export default Billing
