import { CardContent, Typography, Divider, CardActions } from "@mui/material";
import { navigate } from "gatsby";
import { Button } from "gatsby-theme-material-ui";
import { Address } from "helpers/types";
import React from "react";
import { routePaths } from "../../constants/routes";
import { StyledCard } from "./Styled";

interface AddressCardProps {
  label: string;
  address: Address;
  displayButton: boolean;
}

const AddressCard = (props: AddressCardProps) => {
  const { label, address, displayButton } = props;
  if (!address) return null;
  return (
    <StyledCard variant="outlined">
      <CardContent>
        <Typography variant="h2">{label}</Typography>
        <Divider />
        <Typography>{`${address.firstName} ${address.lastName}`}</Typography>
        <Typography>{address.address1}</Typography>
        <Typography>{address.address2}</Typography>
        <Typography>{`${address.postalCode} ${address.city}`}</Typography>
        <Typography>{address.country}</Typography>
        <Typography>{address.mobilePhone}</Typography>
        <Typography>{address.landlinePhone}</Typography>
      </CardContent>
      {displayButton && (
        <CardActions>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() =>
              navigate(`${routePaths.address}?id=${address.id}`, {
                state: { redirection: `${routePaths.shoppingCart}?step=3` },
              })
            }
          >
            Mettre à jour
          </Button>
        </CardActions>
      )}
    </StyledCard>
  );
};

export default AddressCard;
