import {
  Button,
  Icon,
  Typography,
  Stepper as MaterialStepper,
  Card
} from '@mui/material'
import { CardElement } from '@stripe/react-stripe-js'
import { Link } from 'gatsby'
import { styled } from '@mui/material/styles'
import { Delete } from '@mui/icons-material'

export const ProductImage = styled('img')`
  border-radius: 5px;
  height: 80px;
`

export const DeleteIcon = styled(Delete)`
  cursor: pointer;
  &:hover {
    color: red;
  }
`

export const TitleLink = styled(Typography)`
  text-decoration: none;
  font-size: 16px;
  &:hover {
    text-decoration: underline;
  }
`

export const Price = styled(Typography)`
  font-size: 16px;
`

export const TotalPrice = styled('div')`
  font-size: 20px;
  font-weight: 500;
`

export const HeaderContainer = styled('div')`
  margin-bottom: 20px;
  padding: 0 15%;
  @media (max-width: 600px) {
    padding: 0;
  }
`

export const Stepper = styled(MaterialStepper)`
  background-color: black;
  margin-bottom: 50px;
  @media (max-width: 600px) {
    padding: 0px;
  }
`

export const NextButtonContainer = styled('div')`
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  @media (max-width: 600px) {
    flex-direction: column;
    gap: 16px;
  }
`

export const TermsLink = styled(Link)`
  color: white;
`

export const StyledCardElement = styled(CardElement)`
  border-radius: 4px;
  padding: 12px;
  border: 1px solid rgba(50, 50, 93, 0.1);
  max-height: 44px;
  color: white;
  background: black;
  box-sizing: border-box;
  border: 1px solid white;
`

export const BillingContainer = styled('div')`
  display: flex;
  @media (max-width: 800px) {
    flex-direction: column;
    gap: 16px;
  }
`

export const LeftContainer = styled('div')`
  width: 40%;
  @media (max-width: 800px) {
    width: 100%;
    text-align: center;
  }
`
export const RightContainer = styled('div')`
  width: 60%;
  display: flex;
  align-items: center;
  flex-direction: column;
  @media (max-width: 800px) {
    width: 100%;
  }
`

export const ProductsList = styled('div')`
  border: 1px solid white;
  border-radius: 4;
  padding: 10px 5px;
  display: flex;
  width: 80%;
  gap: 10px;
  flex-direction: column;
  @media (max-width: 800px) {
    width: 100%;
  }
`

export const ProductPreview = styled('img')`
  width: 40px;
  height: 40px;
  margin-right: 10px;
  border-radius: 4px;
`

export const ProductDetail = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

export const ProductsContainer = styled('div')`
  display: flex;
  align-items: center;
`

export const ShippingContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
`

export const TotalContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
`

export const BillingTitleContainer = styled('div')`
  display: flex;
  align-items: center;
  gap: 25px;
  margin-bottom: 20px;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`

export const AcceptedPaymentContainer = styled('div')`
  display: flex;
  align-items: center;
  gap: 10px;
`

export const CardElementContainer = styled('div')`
  width: 350px;
  margin-bottom: 30px;
`

export const AddressesContainer = styled('div')`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 30px;
`

export const AddressesCardsContainer = styled('div')`
  display: flex;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`

export const TextFieldContainer = styled('div')`
  display: flex;
  flex-direction: column;
  min-height: 100px;
  @media (max-width: 800px) {
    min-height: 0px;
    align-items: center;
  }
`

export const ShippingOptionContainer = styled('div')`
  display: flex;
  flex-direction: column;
  @media (max-width: 800px) {
    align-content: center;
  }
`

export const LeftAddressContainer = styled('div')`
  width: 50%;
  @media (max-width: 800px) {
    width: 100%;
  }
`
export const RightAddressContainer = styled('div')`
  width: 50%;
  @media (max-width: 800px) {
    width: 100%;
  }
`

export const StyledCard = styled(Card)`
  max-width: 80%;
  min-width: 250;
  width: 80%;
  margin: 0 15px;
  @media (max-width: 800px) {
    max-width: 100%;
    width: 100%;
  }
`
export const EmptyAddressesContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 25px;
`

export const SuccessPaymentContainer = styled('div')`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 30px;
`

export const CountContainer = styled('div')`
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
`
