import React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
  DialogContentText
} from '@mui/material'
import { buildImageLink } from '../../helpers'

const DeleteDialog = props => {
  const { handleClose, handleDelete, selectedProduct } = props
  const { product, productQuantities } = selectedProduct

  const handleConfirm = () => {
    handleDelete(product.id)
    handleClose()
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle id="simple-dialog-title">
        Voulez-vous retirer ce produit de votre panier ?
      </DialogTitle>
      <DialogContent
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '20px'
        }}
      >
        <img
          src={buildImageLink(product.images[0].url)}
          alt={product.images[0].alternativeText}
          style={{ maxWidth: '300px', borderRadius: '20px' }}
        />
        <DialogContentText id="alert-dialog-slide-description" mt={2}>
          {`${productQuantities} x ${product.title}`}
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'space-evenly' }}>
        <Button onClick={handleClose} color="secondary" variant="outlined">
          Annuler
        </Button>
        <Button onClick={handleConfirm} variant="contained" color="error">
          Retirer
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DeleteDialog
