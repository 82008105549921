import axios from 'axios'

export const createContact = async (
  name: string,
  message: string,
  email: string,
  toOrder: string,
  token: string
) => {
  try {
    const response = await axios.post(
      `${process.env.GATSBY_STRAPI_URL}/api/create-contact`,
      {
        name,
        email,
        message,
        toOrder,
        token
      }
    )
    return response.data
  } catch (e) {
    console.error(e)
    throw new Error('Une erreur est survenue')
  }
}

export default createContact
