import axios from 'axios'
import { formatAttributes } from '..'

export const getDeliveryModes = async () => {
  try {
    const response = await axios.get(
      `${process.env.GATSBY_STRAPI_URL}/api/deliveries?populate=*`
    )
    return formatAttributes(response.data.data)
  } catch (e) {
    console.error(e)
    throw new Error('Une erreur est survenue')
  }
}

export const getDeliveryPrices = async (orderId: number) => {
  try {
    const response = await axios.get(
      `${process.env.GATSBY_STRAPI_URL}/api/delivery-prices/${orderId}`
    )
    return response.data
  } catch (e) {
    console.error(e)
    throw new Error('Une erreur est survenue')
  }
}
