import React, { useEffect, useState } from 'react'
import {
  Button,
  Radio,
  Typography,
  List,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Avatar,
  ListItemAvatar,
  ListItemButton
} from '@mui/material'
import { navigate } from 'gatsby'
import { LoadingButton } from '@mui/lab'
import { ChevronLeft, ChevronRight } from '@mui/icons-material'

import { NextButtonContainer, TermsLink } from './Styled'
import { routePaths } from '../../constants/routes'
import { useProfileContext } from '../../contexts/profileContext'
import { DeliveryMode } from '../../helpers/types'
import { buildImageLink, formatPrice } from '../../helpers'
import { getDeliveryPrices } from '../../helpers/api/deliveryModes'

const Shipping = () => {
  const profile = useProfileContext()
  const user = profile?.user
  const order = profile?.order
  const updateOrder = profile?.updateOrder
  const [loading, setLoading] = useState(true)
  const [selectedShipping, setSelectedShipping] = useState<number>(
    order?.deliveryMode?.id || undefined
  )
  const [submitting, setSubmitting] = useState(false)
  const [deliveryModes, setDeliveryModes] = useState<DeliveryMode[]>([])

  useEffect(() => {
    if ((!user && !order) || !order) navigate(routePaths.shoppingCart)
  }, [order])

  useEffect(() => {
    const fetchDeliveryPrices = async () => {
      const result = await getDeliveryPrices(order.id)
      setDeliveryModes(result)
      setLoading(false)
    }

    if (order?.id) fetchDeliveryPrices()
  }, [order])

  if (loading) return <Typography>loading</Typography>

  const hasNotDeliverableProducts = order?.products.some(
    product => !product.deliverable
  )

  const handleSubmit = async () => {
    setSubmitting(true)
    try {
      await updateOrder({
        deliveryMode: selectedShipping
      })
      navigate(`${routePaths.shoppingCart}?step=5`)
    } catch (e) {
      console.error(e)
    } finally {
      setSubmitting(false)
    }
  }

  const handleToggle = id => setSelectedShipping(id)

  const shippingAddress = order?.shippingAddress

  return (
    <>
      <div>
        <Typography variant="h5">
          {`Choisissez une option de livraison pour l'adresse : 
          ${shippingAddress?.addressName}`}
        </Typography>
        <List>
          {deliveryModes.map(
            ({
              id,
              price,
              isHomeDelivery,
              logo,
              title,
              description
            }: DeliveryMode) => {
              const labelId = `checkbox-list-label-${id}`
              const disabled = isHomeDelivery && hasNotDeliverableProducts

              const formattedPrice =
                price === 0 ? 'Gratuit !' : formatPrice(price)

              return (
                <ListItemButton
                  key={id}
                  dense
                  onClick={() => handleToggle(id)}
                  disabled={disabled}
                >
                  <ListItemIcon>
                    <Radio
                      edge="start"
                      checked={selectedShipping === id}
                      tabIndex={-1}
                      color="primary"
                      disableRipple
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                  </ListItemIcon>
                  <ListItemAvatar>
                    <Avatar
                      alt={logo.alternativeText}
                      src={buildImageLink(logo.url)}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    id={labelId}
                    primary={title}
                    secondary={description}
                  />
                  <ListItemSecondaryAction>
                    <Typography>{disabled ? '' : formattedPrice}</Typography>
                  </ListItemSecondaryAction>
                </ListItemButton>
              )
            }
          )}
          {hasNotDeliverableProducts && (
            <Typography color="primary">
              Un de vos produit n&#39;est pas disponible à la livraison à
              domicile.
            </Typography>
          )}
        </List>
        <Typography variant="h5" style={{ marginTop: 30 }}>
          Conditions générales de ventes
        </Typography>
        <Typography variant="body2" style={{ marginTop: 20 }}>
          {`En validant ma commande, j'accepte et reconnais avoir pris connaissance
          de l'intégralité des Conditions Générales de Ventes. `}
          <TermsLink to={routePaths.termsOfSale}>
            (Lire les Conditions générales de ventes)
          </TermsLink>
        </Typography>
      </div>
      <NextButtonContainer>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => navigate(`${routePaths.shoppingCart}?step=3`)}
          startIcon={<ChevronLeft />}
          disabled={submitting}
        >
          Précédent
        </Button>
        <LoadingButton
          disabled={!selectedShipping || loading}
          variant="contained"
          color="primary"
          loading={submitting}
          onClick={handleSubmit}
          endIcon={<ChevronRight />}
        >
          Suivant
        </LoadingButton>
      </NextButtonContainer>
    </>
  )
}

export default Shipping
