import React, { useEffect, useState } from 'react'
import {
  Button,
  Checkbox,
  FormControlLabel,
  MenuItem,
  TextField,
  Typography
} from '@mui/material'
import { useLocation } from '@reach/router'
import { Add, ChevronLeft, ChevronRight } from '@mui/icons-material'
import { navigate } from 'gatsby'
import { isEmpty } from 'lodash'
import { LoadingButton } from '@mui/lab'

import { routePaths } from '../../constants/routes'
import { useProfileContext } from '../../contexts/profileContext'
import { useAppContext } from '../../contexts/appContext'
import {
  NextButtonContainer,
  AddressesContainer,
  AddressesCardsContainer,
  TextFieldContainer,
  ShippingOptionContainer,
  LeftAddressContainer,
  RightAddressContainer,
  EmptyAddressesContainer
} from './Styled'
import AddressCard from './AddressCard'
import { getAddresses } from '../../helpers/api/addresses'
import { Address } from '../../helpers/types'

const Addresses = () => {
  const location = useLocation()
  const state = location.state as { addressId: string }
  const profile = useProfileContext()
  const showSnackbar = useAppContext()?.showSnackbar
  const user = profile?.user
  const order = profile?.order
  const updateOrder = profile?.updateOrder

  const [addresses, setAddresses] = useState<Address[]>([])
  const [loading, setLoading] = useState(true)
  const [checked, setChecked] = useState(true)
  const [shippingAddressId, setShippingAddressId] = useState<number>(
    order?.shippingAddress?.id || undefined
  )
  const [billingAddressId, setBillingAddressId] = useState<number>(
    order?.billingAddress?.id || undefined
  )
  const [message, setMessage] = useState(order?.comment || '')
  const [submitting, setSubmitting] = useState(false)

  useEffect(() => {
    if ((!user && !order) || !order || !user) navigate(routePaths.shoppingCart)
  }, [order])

  useEffect(() => {
    const fetchAddresses = async () => {
      const result = await getAddresses()
      setAddresses(result)
      setLoading(false)
    }
    if (user) fetchAddresses()
  }, [user])

  useEffect(() => {
    if (state?.addressId) {
      setBillingAddressId(parseInt(state.addressId, 10))
      setShippingAddressId(parseInt(state.addressId, 10))
    }
  }, [state])

  const handleChangeAddress = (value: number) => {
    if (checked) setBillingAddressId(value)
    setShippingAddressId(value)
  }

  const handleCheck = e => {
    setChecked(e.target.checked)
    if (e.target.checked) setBillingAddressId(shippingAddressId)
  }

  const shippingAddress = addresses.find(
    address => address.id === shippingAddressId
  ) as Address
  const billingAddress = addresses.find(
    address => address.id === billingAddressId
  ) as Address

  const handleSubmit = async () => {
    setSubmitting(true)
    try {
      await updateOrder({
        shippingAddressId,
        billingAddressId,
        comment: message
      })
      navigate(`${routePaths.shoppingCart}?step=4`)
    } catch (e) {
      showSnackbar('error', "Une erreur s'est produite, veuillez réesayer")
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <>
      {isEmpty(addresses) ? (
        <EmptyAddressesContainer>
          <Typography>
            Vous n&#39;avez pas encore d&#39;adresse enregistrée, vous devez en
            ajouter une :
          </Typography>
          <Button
            variant="contained"
            color="primary"
            style={{ width: 'fit-content' }}
            onClick={() =>
              navigate(`${routePaths.address}`, {
                state: { redirection: `${routePaths.shoppingCart}?step=3` }
              })
            }
          >
            Ajouter une adresse
          </Button>
        </EmptyAddressesContainer>
      ) : (
        <AddressesContainer>
          <AddressesCardsContainer>
            <LeftAddressContainer>
              <ShippingOptionContainer>
                <TextFieldContainer>
                  <TextField
                    id="shippingAddress"
                    select
                    variant="outlined"
                    color="primary"
                    label="Adresse"
                    style={{ minWidth: 250, maxWidth: '30%' }}
                    value={shippingAddressId}
                    onChange={e =>
                      handleChangeAddress(parseInt(e.target.value, 10))
                    }
                  >
                    {addresses?.map(address => (
                      <MenuItem key={address.id} value={address.id}>
                        {address.addressName}
                      </MenuItem>
                    ))}
                  </TextField>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={checked}
                        onClick={handleCheck}
                      />
                    }
                    label="Utiliser la même adresse pour la facturation"
                    labelPlacement="end"
                    style={{ width: 'fit-content' }}
                  />
                </TextFieldContainer>

                <AddressCard
                  label="Votre adresse de livraison"
                  address={shippingAddress}
                  displayButton
                />
              </ShippingOptionContainer>
            </LeftAddressContainer>
            <RightAddressContainer>
              <TextFieldContainer>
                <TextField
                  id="billingAddress"
                  select
                  variant="outlined"
                  color="primary"
                  label="Adresse"
                  style={{
                    minWidth: 250,
                    maxWidth: '30%',
                    display: checked ? 'none' : 'flex'
                  }}
                  value={billingAddressId}
                  onChange={e =>
                    setBillingAddressId(parseInt(e.target.value, 10))
                  }
                >
                  {addresses?.map(address => (
                    <MenuItem key={address.id} value={address.id}>
                      {address.addressName}
                    </MenuItem>
                  ))}
                </TextField>
              </TextFieldContainer>
              <AddressCard
                label="Votre adresse de facturation"
                address={billingAddress}
                displayButton={shippingAddressId !== billingAddressId}
              />
            </RightAddressContainer>
          </AddressesCardsContainer>

          <Button
            variant="outlined"
            color="secondary"
            style={{ width: 'fit-content' }}
            onClick={() =>
              navigate(`${routePaths.address}`, {
                state: { redirection: `${routePaths.shoppingCart}?step=3` }
              })
            }
            startIcon={<Add />}
          >
            Ajouter une nouvelle adresse
          </Button>
          <Typography>
            Si vous voulez nous laisser un message à propos de votre commande,
            veuillez l&#39;écrire ici :
          </Typography>
          <TextField
            variant="outlined"
            type="text"
            value={message}
            multiline
            rows={5}
            maxRows={5}
            style={{
              minWidth: 100,
              width: '100%',
              borderColor: 'white',
              height: 'fit-content'
            }}
            label="Rédigez votre Message"
            onChange={e => setMessage(e.target.value)}
          />
        </AddressesContainer>
      )}
      <NextButtonContainer>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => navigate(routePaths.shoppingCart)}
          startIcon={<ChevronLeft />}
          disabled={submitting}
        >
          Précédent
        </Button>
        <LoadingButton
          disabled={(!shippingAddressId && !billingAddressId) || loading}
          variant="contained"
          color="primary"
          loading={submitting}
          onClick={handleSubmit}
          endIcon={<ChevronRight />}
        >
          Suivant
        </LoadingButton>
      </NextButtonContainer>
    </>
  )
}

export default Addresses
