import axios from 'axios'

export const confirmPaymentIntent = async params => {
  try {
    const response = await axios.post(
      `${process.env.GATSBY_STRAPI_URL}/api/confirm-payment-intent`,
      params
    )
    return response.data
  } catch (e) {
    console.error(e)
    throw new Error('Une erreur est survenue')
  }
}

export default confirmPaymentIntent
