import React, { useState, useEffect } from 'react'
import {
  Table,
  IconButton,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Typography,
  Skeleton,
  Tooltip
} from '@mui/material'
import { Link, navigate } from 'gatsby'
import _ from 'lodash'

import { Add, ChevronLeft, ChevronRight, Remove } from '@mui/icons-material'
import { buildImageLink, formatPrice, getTotalPrice } from '../../helpers'
import {
  DeleteIcon,
  TitleLink,
  Price,
  TotalPrice,
  ProductImage,
  NextButtonContainer,
  CountContainer
} from './Styled'
import { useProfileContext } from '../../contexts/profileContext'
import DeleteDialog from './DeleteDialog'
import { routePaths } from '../../constants/routes'
import { Product } from '../../helpers/types'
import {
  getLSValue,
  LocalStorageKey,
  setLSValue
} from '../../helpers/localStorage'
import { useAppContext } from '../../contexts/appContext'

const ShoppingCartTable = () => {
  const profile = useProfileContext()
  const showSnackbar = useAppContext()?.showSnackbar
  const [loading, setLoading] = useState(true)
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [isOpen, setIsOpen] = useState(false)
  const user = profile?.user
  const order = profile?.order
  const updateOrder = profile?.updateOrder
  const updateLocaleOrder = profile?.updateLocaleOrder

  const hasProducts = !!order?.products?.length

  const totalPrice = order?.totalPrice

  useEffect(() => {
    if (loading && order) setLoading(false)
  }, [order, loading])

  const handleDelete = async (productId: number) => {
    if (user && order) {
      const newProductQuantities = _.omit(order.productQuantities, [productId])

      await updateOrder({
        products: [
          ...order.products.flatMap(product =>
            product.id === productId ? [] : product.id
          )
        ],
        productQuantities: newProductQuantities
      })
    } else {
      const storedOrder = getLSValue(LocalStorageKey.ORDER)
      const newProductQuantities = _.omit(order?.productQuantities, [productId])
      setLSValue(LocalStorageKey.ORDER, {
        products: storedOrder.products.filter(el => el !== productId),
        productQuantities: newProductQuantities
      })
      const products = order.products.filter(el => el.id !== productId)
      const totalPrice = getTotalPrice(products, newProductQuantities)
      updateLocaleOrder({
        products,
        productQuantities: newProductQuantities,
        totalPrice
      })
    }
    showSnackbar('success', 'Produit supprimé')
  }

  const updateShoppingCart = async ({
    quantity,
    product
  }: {
    quantity: number
    product: Product
  }) => {
    if (user && order) {
      await updateOrder({
        productQuantities: {
          ...order.productQuantities,
          [product.id.toString()]: quantity
        },
        products: order.products.map(({ id }) => id)
      })
    } else {
      const storedOrder = getLSValue(LocalStorageKey.ORDER)
      setLSValue(LocalStorageKey.ORDER, {
        ...storedOrder,
        productQuantities: {
          ...storedOrder.productQuantities,
          [product.id.toString()]: quantity
        }
      })
      const productQuantities = {
        ...order.productQuantities,
        [product.id.toString()]: quantity
      }
      const totalPrice = getTotalPrice(order.products, productQuantities)
      updateLocaleOrder({
        ...order,
        productQuantities,
        totalPrice
      })
    }
  }

  const handleClose = () => setIsOpen(false)

  const handleOpen = (product, productQuantities) => {
    setSelectedProduct({ product, productQuantities })
    setIsOpen(true)
  }

  return (
    <>
      {hasProducts ? (
        <div>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center" style={{ width: '100px' }}>
                    Produit
                  </TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell align="right">Prix unitaire</TableCell>
                  <TableCell align="center" style={{ width: '200px' }}>
                    Quantité
                  </TableCell>
                  <TableCell />
                  <TableCell align="right">Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell>
                      <Skeleton height={100} animation="wave" />
                    </TableCell>
                    <TableCell>
                      <Skeleton height={100} animation="wave" />
                    </TableCell>
                    <TableCell>
                      <Skeleton height={100} animation="wave" />
                    </TableCell>
                    <TableCell>
                      <Skeleton height={100} animation="wave" />
                    </TableCell>
                    <TableCell>
                      <Skeleton height={100} animation="wave" />
                    </TableCell>
                    <TableCell>
                      <Skeleton height={100} animation="wave" />
                    </TableCell>
                  </TableRow>
                ) : (
                  order.products.map(product => {
                    const productQuantity = order.productQuantities[product.id]
                    return (
                      <TableRow key={product.id}>
                        <TableCell
                          align="center"
                          component="th"
                          scope="row"
                          style={{ width: '100px' }}
                        >
                          <ProductImage
                            src={buildImageLink(product.images[0].url)}
                            alt={product.images[0].alternativeText}
                          />
                        </TableCell>
                        <TableCell>
                          <Link
                            to={routePaths.product.replace(
                              ':id',
                              product.id.toString()
                            )}
                            style={{ textDecoration: 'none' }}
                          >
                            <TitleLink color="secondary">
                              {product.title}
                            </TitleLink>
                          </Link>
                        </TableCell>
                        <TableCell align="right">
                          {product.promotion ? (
                            <>
                              <Typography
                                variant="body2"
                                sx={{ textDecoration: 'line-through' }}
                              >
                                {formatPrice(product.price)}
                              </Typography>
                              <Typography variant="body2">
                                {formatPrice(product.promotion)}
                              </Typography>
                            </>
                          ) : (
                            formatPrice(product.price)
                          )}
                        </TableCell>
                        <TableCell align="center">
                          <CountContainer>
                            {product.quantity > 1 && (
                              <Tooltip arrow title="Retirer 1">
                                <IconButton
                                  aria-label="remove"
                                  color="primary"
                                  disabled={productQuantity <= 1}
                                  onClick={() => {
                                    const quantity = productQuantity - 1
                                    if (quantity) {
                                      return updateShoppingCart({
                                        quantity,
                                        product
                                      })
                                    }
                                    return null
                                  }}
                                >
                                  <Remove />
                                </IconButton>
                              </Tooltip>
                            )}
                            {productQuantity}
                            {product.quantity > 1 && (
                              <Tooltip arrow title="Ajouter 1">
                                <IconButton
                                  aria-label="add"
                                  color="primary"
                                  disabled={
                                    productQuantity === product.quantity
                                  }
                                  onClick={() => {
                                    const quantity = productQuantity + 1
                                    if (quantity) {
                                      return updateShoppingCart({
                                        quantity,
                                        product
                                      })
                                    }
                                    return null
                                  }}
                                >
                                  <Add />
                                </IconButton>
                              </Tooltip>
                            )}
                          </CountContainer>
                        </TableCell>
                        <TableCell align="center">
                          <Tooltip arrow title="Supprimer">
                            <DeleteIcon
                              onClick={() =>
                                handleOpen(product, productQuantity)
                              }
                            />
                          </Tooltip>
                        </TableCell>
                        <TableCell align="right">
                          <Price>
                            {formatPrice(
                              (product.promotion || product.price) *
                                productQuantity
                            )}
                          </Price>
                        </TableCell>
                      </TableRow>
                    )
                  })
                )}
                <TableRow>
                  <TableCell colSpan={3} />
                  <TableCell align="right">
                    <TotalPrice>Total</TotalPrice>
                  </TableCell>
                  <TableCell colSpan={2} align="right">
                    {loading ? (
                      <Skeleton height={50} animation="wave" />
                    ) : (
                      <TotalPrice>{formatPrice(totalPrice || 0)}</TotalPrice>
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <NextButtonContainer>
            <Button
              onClick={() => navigate(routePaths.shop)}
              color="secondary"
              variant="outlined"
              startIcon={<ChevronLeft />}
            >
              Continuer mes achats
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() =>
                navigate(`${routePaths.shoppingCart}?step=${user ? '3' : '2'}`)
              }
              disabled={loading}
              endIcon={<ChevronRight />}
            >
              Finaliser ma commande
            </Button>
          </NextButtonContainer>
        </div>
      ) : (
        <Typography align="center">Votre panier est vide</Typography>
      )}
      {isOpen && (
        <DeleteDialog {...{ handleClose, handleDelete, selectedProduct }} />
      )}
    </>
  )
}

export default ShoppingCartTable
