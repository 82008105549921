import React, { useEffect, useState } from 'react'
import { graphql, navigate } from 'gatsby'
import { Grid, Step, StepLabel, useMediaQuery, useTheme } from '@mui/material'
import { Elements } from '@stripe/react-stripe-js'

import SEO from '../components/common/seo'
import { routePaths } from '../constants/routes'
import { useProfileContext } from '../contexts/profileContext'
import { getStripe } from '../helpers/stripe'
import Table from '../components//ShoppingCart/ShoppingCartTable'
import { getParams } from '../helpers/urlParams'
import { EventClient, useEventClient } from '../helpers/appClientEvent'
import { Stepper } from '../components/ShoppingCart/Styled'
import LoginForm from '../components/LoginForm'
import RegisterForm from '../components/RegisterForm'
import Addresses from '../components/ShoppingCart/Addresses'
import Shipping from '../components/ShoppingCart/Shipping'
import Billing from '../components/ShoppingCart/Billing'

const promise = getStripe()

function getStepContent(stepIndex: number) {
  switch (stepIndex) {
    case 0:
      return <Table />
    case 1:
      return (
        <Grid container justifyContent="space-evenly" flexWrap="wrap" gap={4}>
          <RegisterForm />
          <LoginForm />
        </Grid>
      )
    case 2:
      return <Addresses />
    case 3:
      return <Shipping />
    case 4:
      return (
        <Elements stripe={promise}>
          <Billing />
        </Elements>
      )
    default:
      return 'Unknown stepIndex'
  }
}

const ShoppingCartPage = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const user = useProfileContext()?.user

  const step = getParams('step')?.step || '1'
  const [activeStep, setActiveStep] = useState(parseInt(step as string, 10) - 1)
  const steps = ['Panier', 'Identification', 'Adresse', 'Livraison', 'Paiement']

  useEventClient(EventClient.UPDATE_AFTER_LOGIN, () => {
    setActiveStep(2)
    navigate(`${routePaths.shoppingCart}?step=3`)
  })

  useEffect(() => {
    if (
      user &&
      window.location.pathname + window.location.search ===
        `${routePaths.shoppingCart}?step=2`
    )
      navigate(`${routePaths.shoppingCart}?step=3`)
  }, [user])

  useEffect(() => {
    setActiveStep(parseInt(step as string, 10) - 1)
  }, [step])

  return (
    <div style={{ width: isMobile ? '100%' : '70%', margin: 'auto' }}>
      <SEO title="Panier" />
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map(label => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {getStepContent(activeStep)}
    </div>
  )
}

export default ShoppingCartPage
